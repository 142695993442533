import React from 'react';
import { Link } from 'gatsby';

export default ({ data }) => {
  const posts = data.allMarkdownRemark.edges;

  return (
    <div>
      <div className="list-group list-group-flush">
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          return (
            <Link
              to={node.fields.slug}
              key={node.fields.slug}
              className="list-group-item list-group-item-action"
            >
              <span>{node.frontmatter.date}</span>
              <span style={{ paddingLeft: 10, color: '#2d8190' }}>{title}</span>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
